import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LanguageProps } from "../../interfaces";
import pawns from "../../assets/svgs/chess-pieces.svg";
import { Button } from "../../components/shadcn/components/ui/button";
import { IonIcon } from "@ionic/react";
import "./NotFound.css";
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

const NotFound: React.FC<LanguageProps> = ({ activeLanguage }) => {
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNavigateHome = () => {
        navigate(`${activeLanguage == 'en' ? '/en' : ''}/`);
    };

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    return (
        <div className="min-h-screen bg-neutral-900 flex items-center justify-center pt-[100px]">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-center max-w-desktop m-auto gap-10 md:gap-20 px-4">
                <div className="order-2 md:order-1">
                    <img src={pawns} alt="Chess pawns" className="w-full max-w-md mx-auto" />
                </div>
                <div className="text-neutral-100 flex flex-col w-full justify-center gap-4 order-1 md:order-2">
                    <h1 className="text-6xl md:text-8xl font-bold text-pred">404</h1>
                    <h3 className="text-2xl md:text-3xl font-semibold ">
                        {languageData.notFound.title}
                    </h3>
                    <p className="text-lg">
                        {languageData.notFound.message}
                    </p>
                    <div>
                        <Button
                            className="bg-neutral-800 w-auto hover:bg-neutral-700 gap-1.5 text-neutral-100 to-home"
                            onClick={handleNavigateHome}
                        >
                            {languageData.notFound.homeButton}
                            <IonIcon className="mt-[1px] to-home-icon duration-200" icon="chevron-forward" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;