import { LanguageProps } from "../../interfaces";


const News:React.FC<LanguageProps> = ({activeLanguage}) => {
    return (
        <div>
            <h1>News</h1>
        </div>
    );
}

export default News;