import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import News from './pages/News/News';
import NotFound from './pages/NotFound/NotFound';

import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';

interface LanguageChangerProps {
  activeLanguage: string;
  setActiveLanguage: (lang: string) => void;
}

interface LanguageInitializerProps {
  onLanguageDetected: (lang: string) => void;
}

const LanguageInitializer: React.FC<LanguageInitializerProps> = ({ onLanguageDetected }) => {
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const langSlug = pathSegments[1];
    if (['en', 'el'].includes(langSlug)) {
      onLanguageDetected(langSlug);
    }
  }, [location, onLanguageDetected]);

  return null;
};

const getPath = (path: string, activeLanguage: string) => {
  return activeLanguage === 'el' ? path : `/:lang${path}`;
};

const AppRoutes: React.FC<LanguageChangerProps> = ({ activeLanguage, setActiveLanguage }) => (
  <Routes>
    <Route path={getPath('/', activeLanguage)} element={<Home activeLanguage={activeLanguage} />} />
    <Route path={getPath('/news', activeLanguage)} element={<News activeLanguage={activeLanguage} />} />
    <Route path={getPath('/about', activeLanguage)} element={<About activeLanguage={activeLanguage} />} />
    <Route path="*" element={<NotFound activeLanguage={activeLanguage} />} />
  </Routes>
);

const App: React.FC = () => {
  const [activeLanguage, setActiveLanguage] = useState('el');

  const handleLanguageChange = (language: string) => {
    setActiveLanguage(language);
  };

  return (
    <BrowserRouter>
      <LanguageInitializer onLanguageDetected={handleLanguageChange} />
      <Navbar activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
      <AppRoutes activeLanguage={activeLanguage} setActiveLanguage={handleLanguageChange} />
      <Footer activeLanguage={activeLanguage} />
    </BrowserRouter>
  );
};

export default App;