import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import logo from '../../assets/images/logo_min_hd.png';
import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

interface FooterProps {
    activeLanguage: string;
}

const Footer: React.FC<FooterProps> = ({ activeLanguage }) => {
    const [qrPopup, setQrPopup] = useState(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const toggleQrPopup = () => {
        setQrPopup(!qrPopup);
    }

    return (
        <div className='w-screen bg-neutral-900 text-neutral-100 text-sm'>
            <div className='m-auto max-w-desktop px-4 1240:px-0 select-none '>
                <div className='grid sm:grid-cols-2 xmd:grid-cols-4 py-8'>
                    <div className='flex flex-col items-start basis-[25%] mb-7 md:mb-0'>
                        <img src={logo} alt="" className="max-h-36" />
                    </div>
                    <div className='flex flex-col items-start'>
                        <div className='text-md font-semibold mb-6 text-neutral-100'>
                            {languageData.menu.club}
                        </div>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/play`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.about}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/watch`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.competitive}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/learn`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.dropdown.lessons}
                        </Link>

                    </div>
                    <div className='flex flex-col items-start mt-4 xmd:mt-0'>
                        <div className='text-md font-semibold mb-6 text-neutral-100'>
                            {languageData.menu.dropdown.lessons}
                        </div>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/tournaments`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.adult_classes}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/puzzles`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.children_classes}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/forum`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.competitive_classes}
                        </Link>
                    </div>
                    <div className='flex flex-col items-start mt-4 xmd:mt-0'>
                        <div className='text-md font-semibold mb-6 text-neutral-100'>
                            {languageData.menu.communication}
                        </div>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/about`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.about}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/press`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.press}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/news`} className='font-semibold mb-3 text-neutral-300 cursor-pointer hover:text-neutral-100 duration-100'>
                            {languageData.menu.news}
                        </Link>
                    </div>
                </div>
                <div className='flex justify-center xmd:justify-between items-center py-4 '>
                    <div className=''>
                        <div className="flex gap-5 text-neutral-100 items-center justify-center">
                            <a href="https://www.instagram.com/chess" target='_blank' className='h-full flex items-center'>
                                <IonIcon icon="logo-instagram" className="text-lg xmd:text-base cursor-pointer hover:text-neutral-300 duration-100" />
                            </a>
                            <a href="https://www.facebook.com/chess" target='_blank' className='h-full flex items-center'>
                                <IonIcon icon="logo-facebook" className="text-lg xmd:text-base cursor-pointer hover:text-neutral-300 duration-100" />
                            </a>
                            <a href="https://twitter.com/chess" target='_blank' className='h-full flex items-center'>
                                <IonIcon icon="logo-twitter" className="text-lg xmd:text-base cursor-pointer hover:text-neutral-300 duration-100" />
                            </a>
                            <a href="https://www.youtube.com/chess" target='_blank' className='h-full flex items-center'>
                                <IonIcon icon="logo-youtube" className="text-lg xmd:text-base cursor-pointer hover:text-neutral-300 duration-100" />
                            </a>
                        </div>
                    </div>
                    <div className='xmd:block hidden'>
                        <button onClick={toggleQrPopup} className="inline-block text-sm btn btn-primary font-bold bg-neutral-600 py-2 px-4 rounded-full text-neutral-100 hover:bg-neutral-500 duration-100">
                            {languageData.menu.signup}
                        </button>
                    </div>
                </div>
                <div className='py-4 flex xmd:flex-row flex-col-reverse gap-3 xmd:justify-between items-center border-t border-pred'>
                    <div className='text-neutral-400 text-sm'>
                        &#169; {new Date().getFullYear()} petralonachess.gr
                    </div>
                    <div className="flex text-xs gap-3">
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/terms-and-conditions`} className="text-neutral-400 hover:text-neutral-300 cursor-pointer">
                            {languageData.menu.tc}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/privacy-policy`} className="text-neutral-400 hover:text-neutral-300 cursor-pointer">
                            {languageData.menu.privacy}
                        </Link>
                        <Link to={`${activeLanguage == 'en' ? '/en' : ''}/support`} className="text-neutral-400 hover:text-neutral-300 cursor-pointer">
                            {languageData.menu.menu_support}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer;