import { LanguageProps } from "../../interfaces";


const About:React.FC<LanguageProps> = ({activeLanguage}) => {

    return (
        <div>
            <h1>About</h1>
        </div>
    )
}

export default About;