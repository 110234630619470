import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Sling as Hamburger } from 'hamburger-react';
import { IonIcon } from '@ionic/react';
import { RemoveScroll } from 'react-remove-scroll';
import logo from '../../assets/images/logo_min_hd.png';
import grLoc from '../../assets/images/lang_icons/gr_loc.svg';
import enLoc from '../../assets/images/lang_icons/en_loc.svg';
import MenuSidebarItem from "./MenuSidebarItem/MenuSidebarItem";

import greekLanguage from '../../locales/gr.json';
import englishLanguage from '../../locales/en.json';

interface NavbarProps {
    activeLanguage: string;
    setActiveLanguage: (language: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ activeLanguage, setActiveLanguage }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuClosing, setMenuClosing] = useState(false);
    const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
    const [isLanguageMenuClosing, setLanguageMenuClosing] = useState(false);
    const [inWhiteBackground, setInWhiteBackground] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [isOnTop, setIsOnTop] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);

    const languageMenuRef = useRef<HTMLDivElement | null>(null);
    const languageButtonRef = useRef<HTMLDivElement | null>(null);
    const mainMenuRef = useRef<HTMLDivElement | null>(null);

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const handleMenuToggle = (clicked_outside?: boolean) => {
        if (isMenuOpen || clicked_outside) {
            setMenuClosing(true);
            setTimeout(() => {
                setMenuClosing(false);
                setMenuOpen(false);
            }, 501);
        } else {
            setMenuOpen(!isMenuOpen);
        }
    };

    const handleLanguageMenuToggle = (clicked_outside?: boolean) => {
        if (isLanguageMenuOpen || clicked_outside) {
            setLanguageMenuClosing(true);
            setTimeout(() => {
                setLanguageMenuClosing(false);
                setLanguageMenuOpen((prev) => !prev);
            }, 501);
        } else {
            setLanguageMenuOpen((prev) => !prev);
        }
    };

    const setLanguage = (language: string) => {
        handleLanguageMenuToggle();
        setActiveLanguage(language);
        const currentPath = window.location.pathname;
        const pathWithoutLang = currentPath.replace(/^\/(en|el)/, '') || '/';
        const newPath = language === 'en' ? `/en${pathWithoutLang}` : pathWithoutLang;
        navigate(newPath);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY === 0) {
                setIsFixed(false);
                setIsOnTop(true);
            } else if (lastScrollY > currentScrollY) {
                setIsFixed(true);
                setIsOnTop(false);
            } else if (lastScrollY < currentScrollY && isFixed) {
                setIsFixed(false);
                setIsOnTop(false);
            }
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY, isFixed]);

    useEffect(() => {
        const pathWithoutLang = activeLanguage === 'en'
            ? location.pathname.replace(`/en`, '')
            : location.pathname;

        const wBMainCondition = ![
            '/',
            '/registration_form',
            '/tournaments',
            '/lessons',
            '/about',
            '/contact',
            '/privacy-policy',
            '/terms-and-conditions',
            '/admin',
            '/login'
        ].includes(pathWithoutLang);

        setInWhiteBackground(wBMainCondition);
    }, [location, activeLanguage]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (languageMenuRef.current && !languageMenuRef.current.contains(event.target as Node) &&
                languageButtonRef.current && !languageButtonRef.current.contains(event.target as Node)) {
                handleLanguageMenuToggle(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (location.pathname.startsWith('/admin') || location.pathname.startsWith('/registration_form')) {
        return null;
    }

    return (
        <div className={`${isFixed ? 'fixed bg-neutral-800 shadow-lg animate-nav-slide-in' : (isOnTop ? 'absolute' : 'fixed top-[-100%] bg-neutral-600 animate-nav-slide-out shadow-lg')} w-full justify-start z-20 ${isMenuOpen ? 'bg-neutral-800 w-full duration-200 border-b border-b-pred' : ''}`}>
            <div className={`max-w-desktop w-full flex justify-between m-auto h-15 py-2 text-sm`}>
                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} className="flex items-center pl-4 1240:pl-0">
                    <img src={logo} alt="" className="h-20" />
                </Link>
                <div className="flex items-center text-neutral-100 text-header select-none">
                    <div className="relative flex items-center">
                        <div ref={languageButtonRef} className="flex items-center gap-1.5 scale-105 xmd:scale-none cursor-pointer" onClick={() => handleLanguageMenuToggle(false)}>
                            <img src={`${activeLanguage == 'el' ? grLoc : enLoc}`} alt={`${activeLanguage == 'el' ? 'greek flag' : 'english flag'}`} className="h-[17px]" />
                            <div className={` ${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-100' : 'text-neutral-100'} duration-200  hidden 1200:block`}>{languageData.menu.menu_language}</div>
                            <div className={` ${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-100' : 'text-neutral-100'} duration-200 block 1200:hidden text-base`}>{languageData.menu.language_short}</div>
                            <IonIcon icon="caret-down" className={` ${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-100' : 'text-neutral-100'} duration-200 text-base hidden 1200:block`} />
                        </div>
                        {isLanguageMenuOpen && (
                            <RemoveScroll>
                                <div
                                    ref={languageMenuRef}
                                    className={`text-black px-4 select-none max-h-0 fixed flex flex-col gap-2 overflow-hidden bg-neutral-100 1200:bg-transparent 1200:backdrop-blur-md rounded-none
                                                w-full items-center top-0 left-0 1200:top-10 1200:absolute 1200:h-auto 1200:w-52 1200:rounded-md
                                                 1200:shadow-sm z-20 h-screen justify-between border  ${isLanguageMenuClosing ? 'animate-slideOutFadeOut' : 'animate-slideInFadeIn'}
                                    `}
                                >
                                    <div className="w-full flex flex-col gap-2 items-center py-6">
                                        <div className={`mt-10 mb-5 1200:m-0 text-base ${isMenuOpen || inWhiteBackground ? 'xmd:text-neutral-100' : 'xmd:text-neutral-100'} text-neutral-800 1200:text-sm`}>
                                            {languageData.menu.menu_modal_title}
                                        </div>
                                        <div className={`${(activeLanguage === 'el') ? 'bg-black text-neutral-100' : 'bg-neutral-700 text-neutral-100'} border-main border-[1.5px] xmd:border-none w-full text-center  1200:text-left py-2 px-3 rounded-full  cursor-pointer`} onClick={() => setLanguage('el')}>
                                            {languageData.menu.menu_modal_greek}
                                        </div>
                                        <div className={`${(activeLanguage === 'en') ? 'bg-black text-neutral-100' : 'bg-neutral-700 text-neutral-100'} border-main border-[1.5px] xmd:border-none w-full text-center  1200:text-left py-2 px-3 rounded-full cursor-pointer`} onClick={() => setLanguage('en')}>
                                            {languageData.menu.menu_modal_english}
                                        </div>
                                    </div>

                                    <div className='justify-self-end bg-blue-700 mb-10  w-full text-center  1200:hidden mx-4 py-2 px-3 rounded-full text-neutral-100 cursor-pointer bg-pastel_red' onClick={() => handleLanguageMenuToggle(false)}>
                                        {languageData.menu.menu_close_language}
                                    </div>
                                </div>
                            </RemoveScroll>
                        )}
                        <div>

                        </div>
                    </div>
                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/lessons`} className={`${isMenuOpen || inWhiteBackground || isFixed ? 'text-neutral-100' : 'text-neutral-100'} duration-200 cursor-pointer ml-6 mr-8 hidden 1200:block`}>
                        {languageData.menu.menu_support}
                    </Link>
                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/tournaments`} className={`${isMenuOpen || inWhiteBackground || isFixed ? 'bg-neutral-600 text-neutral-100' : 'bg-neutral-600 text-neutral-100'} duration-200 py-2 px-4 mr-4 rounded-full cursor-pointer hidden 1200:block`}>
                        {languageData.menu.signup}
                    </Link>
                    <div className="cursor-pointer ml-0.5 sm:ml-0 scale-[75%] 1200:scale-50 rounded-full">
                        <Hamburger easing="ease-in" color={`${isMenuOpen || inWhiteBackground || isFixed ? 'white' : 'white'}`} label="Show menu" toggled={isMenuOpen} toggle={() => handleMenuToggle(false)} />
                    </div>
                </div>
            </div>
            {isMenuOpen && (
                <RemoveScroll>
                    <div ref={mainMenuRef} className={`max-w-desktop mx-auto overflow-hidden max-h-0 height-without-header 1200:h-auto ease-in-out px-4  ${isMenuClosing ? 'animate-slideOut' : 'animate-slideIn'}`} >
                        <div className="flex flex-col 1200:flex-row pb-8 1200:pb-0 gap-2 select-none height-without-header-and-footer 1200:h-auto">
                            <div className="w-full 1200:w-3/4 1200:h-80 1200:mt-8 mb-0 1200:mb-4 py-4 bg-gray-150 rounded-md">
                                <div className="flex gap-2 flex-col 1200:flex-row text-neutral-100">
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-neutral-600 hover:shadow-sm rounded-md font-normal cursor-pointer">
                                        {languageData.menu.play}
                                    </Link>
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-neutral-600 hover:shadow-sm rounded-md font-normal cursor-pointer" >
                                        {languageData.menu.dropdown.lessons}
                                    </Link>
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-neutral-600 hover:shadow-sm rounded-md font-normal cursor-pointer">
                                        {languageData.menu.watch}
                                    </Link>
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-neutral-600 hover:shadow-sm rounded-md font-normal cursor-pointer" >
                                        {languageData.menu.community}
                                    </Link>
                                    <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} onClick={() => handleMenuToggle(false)} className="py-1 px-2 text-sm hover:bg-neutral-600 hover:shadow-sm rounded-md font-normal cursor-pointer" >
                                        {languageData.menu.tools}
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full 1200:w-1/4 m-0 1200:mt-8 1200:mb-4 py-4 bg-gray-150 rounded-md text-sm overflow-scroll 1200:overflow-hidden ">
                                <div className="border-l-pred border-l px-2">
                                    <MenuSidebarItem onClick={handleMenuToggle} icon={<IonIcon icon="trophy" />} title={languageData.menu.sidebar.tournaments_title} text={languageData.menu.sidebar.tournaments_subtitle} path="/tournaments" />
                                    <MenuSidebarItem onClick={handleMenuToggle} icon={<IonIcon icon="school" />} title={languageData.menu.sidebar.lessons_title} text={languageData.menu.sidebar.lessons_subtitle} path="/lessons" />
                                    <MenuSidebarItem onClick={handleMenuToggle} icon={<IonIcon icon="game-controller" />} title={languageData.menu.sidebar.puzzles_title} text={languageData.menu.sidebar.puzzles_subtitle} path="/puzzles" />
                                    <MenuSidebarItem onClick={handleMenuToggle} icon={<IonIcon icon="people" />} title={languageData.menu.sidebar.forum_title} text={languageData.menu.sidebar.forum_subtitle} path="/forum" />
                                </div>
                            </div>
                        </div>
                        <div className={`${isMenuClosing ? 'animate-fadeOut' : 'animate-fadeIn delay-500'} w-full opacity-0 select-none items-center animate-fadeIn
                             absolute bottom-2 left-0 justify-self-end mx-auto px-0.5 mb-3 1200:mb-14 flex flex-col
                            1200:flex-row 1200:justify-between 1200:animate-none 1200:relative 1200:opacity-100 gap-2 1200:gap-0 border-t border-t-pred pt-4`
                        }
                        >
                            <div className="flex gap-5 text-neutral-100">
                                <a href="https://www.instagram.com/chess" target='_blank' className='h-full flex items-center'>
                                    <IonIcon icon="logo-instagram" className="text-sm text-neutral-100 cursor-pointer" />
                                </a>
                                <a href="https://www.facebook.com/chess" target='_blank' className='h-full flex items-center'>
                                    <IonIcon icon="logo-facebook" className="text-sm text-neutral-100 cursor-pointer" />
                                </a>
                                <a href="https://twitter.com/chess" target='_blank' className='h-full flex items-center'>
                                    <IonIcon icon="logo-twitter" className="text-sm text-neutral-100 cursor-pointer" />
                                </a>
                                <a href="https://www.youtube.com/chess" target='_blank' className='h-full flex items-center'>
                                    <IonIcon icon="logo-youtube" className="text-sm text-neutral-100 cursor-pointer" />
                                </a>
                            </div>
                            <div className="flex text-xs gap-3">
                                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/terms-and-conditions`} onClick={() => handleMenuToggle(false)} className="text-neutral-300 hover:text-neutral-400 cursor-pointer">
                                    {languageData.menu.tc}
                                </Link>
                                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/privacy-policy`} onClick={() => handleMenuToggle(false)} className="text-neutral-300 hover:text-neutral-400 cursor-pointer">
                                    {languageData.menu.privacy}
                                </Link>
                                <div className="text-neutral-300">
                                    petralonachess.gr &copy;  {new Date().getFullYear()}
                                </div>
                            </div>
                        </div>
                    </div>
                </RemoveScroll>
            )}
        </div>
    );
}

export default Navbar;