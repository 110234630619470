import React from 'react';
import { LanguageProps } from "../../interfaces";
import header_img from "../../assets/images/home_header.png";
import transparent_logo from "../../assets/images/logo_min_hd.png";
const Home: React.FC<LanguageProps> = ({ activeLanguage }) => {
    return (
        <div>
            <div className="relative h-[92vh] w-full overflow-hidden">
                <img
                    src={header_img}
                    alt="header"
                    className="absolute inset-0 w-full h-full object-cover object filter brightness-50 "
                    style={{ objectPosition: '50% 62%' }}
                />
                <div className="relative z-10 h-full flex items-center justify-center">
                    <h1 className="text-4xl font-bold text-white">

                    </h1>
                </div>
            </div>
            <div className='bg-neutral-900 h-screen'>

            </div>
        </div>

    );
}

export default Home;
